<template>
  <h2 class="page-title">Signup</h2>
  <form v-on:submit.prevent="register" novalidate>
    <p class="error" v-if="error">{{ error }}</p>
    <div class="form-group">
      <div class="form-input">
        <input type="text" v-model="username" placeholder="Enter Username" v-bind:class="[usernameError ? 'error' : '']">
        <div class="form-error" v-if="usernameError">{{ usernameError }}</div>
      </div>
      <div class="form-input">
        <input type="email" v-model="email" placeholder="Enter Email" v-bind:class="[emailError ? 'error' : '']">
        <div class="form-error" v-if="emailError">{{ emailError }}</div>
      </div>
      <div class="form-input">
        <input type="password" v-model="password" placeholder="Enter Password" v-bind:class="[passwordError ? 'error' : '']">
        <div class="form-error" v-if="passwordError">{{ passwordError }}</div>
      </div>
    </div>
    <div class="form-input form-actions">
      <button class="btn" type="submit">Register</button>
    </div>
  </form>
</template>

<script>
import AuthService from '../services/auth.service'
import store from '../store.js'
import validator from '../services/validator'

export default {
  name: 'Signup',
  data () {
    return {
      error: '',
      username: '',
      usernameError: '',
      email: '',
      emailError: '',
      password: '',
      passwordError: ''
    }
  },
  created () {
    if (store.isLoggedIn) {
      this.$router.push('/dashboard')
    }
  },
  methods: {
    validate: function () {
      this.usernameError = ''
      this.emailError = ''
      this.passwordError = ''
      if (validator.isEmpty(this.username)) {
        this.usernameError = 'The username field is required.'
        return false
      }
      if (!validator.isValidUsername(this.username)) {
        this.usernameError = 'Usernames may only contain lowercase letters, numbers and dashes (-).'
        return false
      }
      if (validator.isEmpty(this.email)) {
        this.emailError = 'The email field is required.'
        return false
      }
      if (!validator.isValidEmail(this.email)) {
        this.emailError = 'Email is not valid.'
        return false
      }
      if (validator.isEmpty(this.password)) {
        this.passwordError = 'The password field is required.'
        return false
      }
      if (!validator.isValidPassword(this.password)) {
        this.passwordError = 'The password must be at least 8 characters long.'
        return false
      }
      return true
    },
    register: function () {
      if (this.validate()) {
        AuthService
          .register({
            username: this.username,
            email: this.email,
            password: this.password
          })
          .then(response => {
            if (response.errors) {
              if (response.errors.username) {
                this.usernameError = response.errors.username.join(' ')
              } else {
                this.usernameError = ''
              }
              if (response.errors.email) {
                this.emailError = response.errors.email.join(' ')
              } else {
                this.emailError = ''
              }
              if (response.errors.password) {
                this.passwordError = response.errors.password.join(' ')
              } else {
                this.passwordError = ''
              }
            } else {
              this.error = ''
              this.usernameError = ''
              this.emailError = ''
              this.passwordError = ''
              this.$router.push('/dashboard')
            }
          })
      }
    }
  }
}
</script>
